<template>
  <ul class="nav nav-tabs nav-tabs--transparent mb-3">
    <li class="nav-item">
      <div
        class="nav-link"
        v-bind:class="{ active: isView('overview') }"
        @click.prevent="onClick('overview')"
      >
        {{ $t("role.orders.rounds.nav.overview") }}
      </div>
    </li>
    <li class="nav-item">
      <div
        class="nav-link"
        v-bind:class="{ active: isView('ddmp') }"
        @click.prevent="onClick('ddmp')"
      >
        {{ $t("role.orders.rounds.nav.ddmp") }}
      </div>
    </li>
    <li class="nav-item">
      <div
        class="nav-link"
        v-bind:class="{ active: isView('wh') }"
        @click.prevent="onClick('wh')"
      >
        {{ $t("role.orders.rounds.nav.wh") }}
      </div>
    </li>
    <li class="nav-item">
      <div
        class="nav-link"
        v-bind:class="{ active: isView('ddmi') }"
        @click.prevent="onClick('ddmi')"
      >
        {{ $t("role.orders.rounds.nav.ddmi") }}
      </div>
    </li>
    <li class="nav-item">
      <div
        class="nav-link"
        v-bind:class="{ active: isView('orders') }"
        @click.prevent="onClick('orders')"
      >
        {{ $t("role.orders.rounds.nav.orders") }}
      </div>
    </li>
    <li class="nav-item">
      <div
        class="nav-link"
        v-bind:class="{ active: isView('incident') }"
        @click.prevent="onClick('incident')"
      >
        {{ $t("role.orders.rounds.nav.incident") }}
      </div>
    </li>
  </ul>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "OrdersRoundNav",

  data() {
    return {};
  },

  computed: {
    ...mapState("rounds", ["filters"]),
  },

  mounted() {
    const { view } = this.$route.query;
    if (view) {
      this.$store.commit("rounds/setView", view);
    }
  },

  methods: {
    isView(view) {
      return this.$store.state.rounds.view === view;
    },

    onClick(view) {
      this.$router.push({ query: { view } }).catch(() => {});
      this.$store.commit("rounds/setView", view);
    },
  },
};
</script>
